<template>
  <b-sidebar
    id="sidebar-address-edit"
    ref="sidebarAddressEdit"
    v-model="isShowSidebar"
    right
    backdrop
    shadow
    no-header
    width="85vw"
    @hidden="$emit('hidden')"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col cols="12" class="text-filter">{{ header }}</b-col>
      </b-row>
      <slot></slot>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    header: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      form: {},
    };
  },
  methods: {
    handleMaxlength(e) {
      if (e.target.value.length == 5) {
        return e.preventDefault();
      }
    },
    async handleChange(val, field) {
      let payload = field.field_choices.find((el) => el.name == val);
      const result = await this.axios.post(
        "/setting/GetDependentFixField",
        payload
      );
      let $set = this.fields.findIndex((el) => el.id == field.field_dependent);

      this.fields[$set].field_choices = result.detail;
    },
    async show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submitAction() {
      this.$emit("submit");
      // this.hide();
    },
    async handleChange(val, field, $set, $set2, clear = true) {
      let payload = field.find((el) => el.name == val);
      const result = await this.axios.post(
        "/setting/GetDependentFixField",
        payload
      );

      this.$nextTick(() => {
        this.options[$set].field_choices = result.data.detail;
      });
      if (clear) {
        this.form[$set] = "";
        if ($set2) this.form[$set2] = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
